<template>
  <div class="mb-4">
      <CRow class="mb-0">
        <CCol lg="3">
          <CInput  v-model="plate_no" placeholder="Plate No."/>
        </CCol>
        <CCol lg="3">
          <CInput v-model="asset_no" placeholder="Asset No."/>
        </CCol>
        <CCol lg="3">
          <v-select 
            label="setting_name" 
            :options="vehicleTypeList.data"
            :reduce="item => item.id"
            v-model="vehicle_type_id"
            placeholder=" Vehicle Type"
          > 
          </v-select> 
        </CCol> 
        <CCol lg="3">
          <v-select 
            label="setting_name" 
            :options="makerModelList.data"
            :reduce="item => item.id"
            v-model="maker_model_id"
            placeholder="Maker"
          > 
          </v-select>  
        </CCol>
       
       <CCol lg="3" v-if="!JSON.parse(window.localStorage.getItem('user_data')).depot_id">
          <v-select 
            label="setting_name" 
            :options="depotList.data"
            :reduce="item => item.id"
            v-model="location"
            placeholder="Depot"
          > 
          </v-select>  
        </CCol> 
        <CCol lg="3" v-else>
          <v-select 
            disabled
            label="setting_name" 
            :options="depotList.data"
            :reduce="item => item.id"
            :value="JSON.parse(window.localStorage.getItem('user_data')).depot_id"
            placeholder="Depot"
          > 
          </v-select>  
        </CCol> 
        <!-- <CCol lg="2">
          <v-select 
            label="setting_name" 
            :options="depotList.data"
            :reduce="item => item.id"
            v-model="location"
            placeholder="Depot"
          > 
         
          </v-select> 
        </CCol> -->
         <CCol lg="3">
          <v-select 
            label="company_owned" 
            :options="[
            	{
            		company_owned: 'Yes',
            		id: '1'
            	},
            	{
            		company_owned: 'No',
            		id: '0'
            	},
            	
            ]"
            :reduce="item => item.id"
            v-model="company_owned"
            placeholder="Company Owned"
          > 
          </v-select>  
        
        </CCol>
        <CCol lg="3">
         <v-select 
            label="vendor_name" 
            :options="subconList.data"
            :reduce="item => item.id"
            v-model="vendor_id"
            placeholder="Subcon"
          > 
          </v-select>
        </CCol>
        

        <CCol lg="2" class="float-right mt-1">
          <CButton size="sm" color="info" variant="outline" style="margin-right: 10px"  @click="search">Search</CButton>
       
          <CButton size="sm" color="info" variant="outline" @click="clearSearch" style="margin-right: 10px"  >Clear Filter</CButton>
         
        </CCol>
      </CRow>
  </div>
</template>

<script>
  import config from '../../config.js';
  import axios from '../../axios';
  import vSelect from 'vue-select'
  import 'vue-select/dist/vue-select.css';
    export default {
        name: "BookingTypeSearch",
        data() {
          return {
            window,
            plate_no: "",
           asset_no: "",
            asset_no: "",
            vehicle_type_id: "",
            maker_model_id: "",
            location : null,
            year: "", 
            vehicleTypeList: {
            	data: []
            },
            depotList: {
              data: []
            },
            
		      	makerModelList: {
			  	  data: []
		      	},
           subconList: {
            	data: []
            },
            vendor_id : "",
            company_owned : ""
          
          }
        },
      mounted() {
        this.getVehicleType();
        this.getMakerModel();
        this.getDepotList();
         this.getSubcon();
      },
      components: {vSelect},
      methods: {
         getDepotList(){
          axios.get(config.api_path+'/reference/settings-list',{
            params:{
              setting_type:'depot'
              }
						})
				.then(response => {
					this.depotList = response.data; 
				})

          // axios.get(config.api_path+'/setting?setting_type=depot&page=1&limit=10000000')
          // .then(response => {
          //   this.depotList = response.data; 
          //   this.depotList.data.unshift({
          //     id: "all",
          //     setting_name: "All"
          //   })
          // })

        },
        search() {
          const data = {
            plate_no: this.plate_no,
           asset_no: this.asset_no,
            vehicle_type_id: this.vehicle_type_id,
            maker_model_id: this.maker_model_id,
            year: this.year, 
            location : this.location,
            company_owned : this.company_owned,
            vendor_id : this.vendor_id
          }
          this.$emit("depot-search-query", data)
        },
        clearSearch() {
          this.plate_no = "";
          this.asset_no = "";
          this.vehicle_type_id = "";
          this.maker_model_id = "";
          this.year = ""; 
          this.location = null;
          this.company_owned = ""
          this.vendor_id = ""
          const data = {
            plate_no: this.plate_no,
           asset_no: this.asset_no,
            vehicle_type_id: this.vehicle_type_id,
            maker_model_id: this.maker_model_id,
            year: this.year, 
            location  : null,
            company_owned : this.company_owned,
            vendor_id : this.vendor_id
            
          }
          this.$emit("depot-search-query", data)
        },

      getSubcon(){
        axios.get(config.api_path+'/reference/vendors-list',{
            params:{
              
              }
						})
				.then(response => {
					this.subconList = response.data; 
				})
        

	    	// axios.get(config.api_path+'/vendor?page=1&limit=10000000')
	    	// .then(response => {
	    	// 	this.subconList = response.data; 
	    	// 	this.subconList.data.unshift({
	    	// 		vendor_name: 'All',
	    	// 		id: 'all'
	    	// 	})
	    	// })

	    },
        getVehicleType(){
          axios.get(config.api_path+'/reference/settings-list',{
            params:{
              setting_type:'vehicle_type'
              }
						})
				.then(response => {
					this.vehicleTypeList = response.data; 
				})
	    	// axios.get(config.api_path+'/setting?setting_type=vehicle_type&page=1&limit=10000000')
	    	// .then(response => {
	    	// 	this.vehicleTypeList = response.data; 
	    	// 	this.vehicleTypeList.data.unshift({
	    	// 		setting_name: 'All',
	    	// 		id: 'all'
	    	// 	})
	    	// })

	    },
      

	    getMakerModel(){
        axios.get(config.api_path+'/reference/settings-list',{
            params:{
              setting_type:'maker_model'
              }
						})
				.then(response => {
					this.makerModelList = response.data; 
				})

	    	// axios.get(config.api_path+'/setting?setting_type=maker_model&page=1&limit=10000000')
	    	// .then(response => {
	    	// 	this.makerModelList = response.data; 
	    	// 	this.makerModelList.data.unshift({
	    	// 		setting_name: 'All',
	    	// 		id: 'all'
	    	// 	})
	    	// })

	    },
      }
    }
</script>

<style scoped>

</style>
